// Hintergrundfarbe ändern umfärben
const grafiker = document.getElementById('grafiker');
gsap.timeline({
    scrollTrigger: {
        id: 'grafikerHintergrundfarbe',
        trigger: grafiker,
        start: 'top top',
        end: 'top top',
        onEnter: () => document.body.setAttribute('data-hintergrund', 'neongruen'),
        onLeaveBack: () => document.body.setAttribute('data-hintergrund', 'weiss'),
        // markers: true,
    }
});

// https://gsap.com/community/forums/topic/30502-mouse-cursor-follow-animation/?do=findComment&comment=152375
let mousetrailBilder = '.grafiker__mousetrail';
gsap.set(mousetrailBilder, {
    xPercent: -50,
    yPercent: -50,
    transformOrigin: 'center',
    scale: 0
});

let initialMouseMove = true;
let mouseMoved = 0;
let timer;

if (grafiker !== null) {
    grafiker.addEventListener('mousemove', (e) => {
        // Wenn es die erste Bewegung (nach Stop) ist
        if (initialMouseMove) {
            // danach ist es nicht mehr die erste Mausbewegung,
            // deshalb muss die Variable ab jetzt false sein
            initialMouseMove = false;

            // Die Bilder von 0 auf 1 skalieren
            gsap.to(mousetrailBilder, {
                scale: 1,
                stagger: 0.02,
                ease: 'sine.out'
            })
        }

        // Bewegt sich die Maus, den Timer stoppen
        clearTimeout(timer);
        // Das Timeout triggert die Funktion mouseStopped,
        // wenn die Maus stillsteht
        timer = setTimeout(mouseStopped, 20);

        function mouseStopped() {
            // Die Variable wieder auf true setzen,
            // damit die nöchste Bewegung wieder die erste ist
            initialMouseMove = true;

            // Die Bilder auf 0 skalieren und somit unsichtbar machen
            gsap.to(mousetrailBilder, {
                scale: 0,
                stagger: 0.02,
                ease: 'sine.out'
            })
        }

        gsap.to(mousetrailBilder, {
            duration: 0.5,
            overwrite: 'auto',
            x: e.clientX,
            y: e.clientY,
            stagger: 0.1,
            ease: 'none'
        });
    });
}